import React from "react";
import styled from "styled-components";
import useProductOptions, { ProductOption } from "hooks/useProductOptions";
import { ILocalizedProduct } from "types";
import SelectColorOptions, { ColorObject } from "components/SelectColorOptions";
import { ShopifyProductVariantSelectedOptions } from "graphql-typings";
import ShopifyImage from "components/ShopifyImage";
import formatMoney from "utils/common/formatMoney";
import {
  getInstantImage,
  getVariantPrice,
  getVariantCompareAtPrice,
} from "./utils";

interface Props {
  product: ILocalizedProduct;
  variantId?: string;
  onVariantChage?: (variantId: string) => void;
  onClick?: (variantId: string) => void;
  className?: string;
}

export default function ProductCard({ product, className, onClick }: Props) {
  const productOptions = useProductOptions();
  const availableVariants = product.shopify.variants.filter(variant => variant.availableForSale)
  const [variantId, setVariantId] = React.useState(
    availableVariants[0].storefrontId || product.shopify.variants[0].storefrontId
  );
  const colors = product.shopify.variants
    .filter((variant) => variant.availableForSale)
    .map((variant): ColorObject => {
      const colorOptions = variant.selectedOptions!.filter(
        (option) => option && isColorOption(option, productOptions)
      );
      return {
        value: variant.storefrontId!,
        hex: colorOptions
          .map((option) => {
            const foundValue = productOptions[option!.name!].values.find(
              (value) => value.shopifyValue === option!.value
            );
            return foundValue && foundValue.color ? foundValue.color.hex : null;
          })
          .filter((hex) => hex !== null) as string[],
      };
    });
  const image = getInstantImage(product, variantId);
  const price = getVariantPrice(product, variantId);
  const compareAtPrice = getVariantCompareAtPrice(product, variantId);

  const isDiscounted =
    !!compareAtPrice &&
    compareAtPrice > 0 &&
    compareAtPrice !== price &&
    price > 0;

  return (
    <Card className={className} onClick={() => onClick && onClick(variantId)}>
      <ShopifyImage
        src={(image && image.originalSrc) || "#"}
        alt={product.dato.title}
        width={200}
      />
      {isDiscounted && (
        <OriginalPrice>
          {formatMoney(compareAtPrice * 100, "€{{amount}}")}
        </OriginalPrice>
      )}
      {console.log("PRICE", price)}
      {price > 0 && (
        <Price isDiscounted={isDiscounted}>
          {formatMoney(price * 100, "€{{amount}}")}
        </Price>
      )}
      <SelectColorOptions
        name={product.shopify.storefrontId!}
        value={variantId}
        options={colors}
        onChange={setVariantId}
        size="small"
      />
    </Card>
  );
}

function isColorOption(
  option: ShopifyProductVariantSelectedOptions,
  allOptions: Record<string, ProductOption>
): boolean {
  const foundOption = allOptions[option.name!];
  return foundOption && foundOption.selectorType === "colore";
}

const Card = styled.div`
  display: flex;
  flex-direction: column;
  ${ShopifyImage} {
    width: 100%;
  }
  &:hover {
    ${(props) => (props.onClick ? "cursor: pointer;" : "")}
  }
`;

const OriginalPrice = styled.div`
  color: #333;
  font-size: 14px;
  letter-spacing: 0.08em;
  text-align: center;
  font-weight: 600;
  padding-top: 5px;
  text-decoration: line-through;
`;

const Price = styled.div<{ isDiscounted?: boolean }>`
  color: #d24017;
  ${({ isDiscounted }) => !isDiscounted && `color: #333;`};
  font-size: 14px;
  letter-spacing: 0.08em;
  text-align: center;
  font-weight: 600;
  padding-top: ${({ isDiscounted }) => (isDiscounted ? 0 : "5px")};
`;
