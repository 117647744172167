import Link from "components/Link";
import ProductCard from "components/ProductCard";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "hooks/useTranslation";
import { ILocalizedProduct } from "types";

interface Props {
  bases: ILocalizedProduct[];
  title: string;
  collection: {
    title: string;
    path: string;
  };
  onSelect: (productId: string, variantId: string) => void;
}

export default function SelectBracelet({
  bases,
  title,
  collection,
  onSelect,
}: Props) {
  const { formatMessage } = useTranslation();
  return (
    <div>
      <Breadcrumb>
        <Item>
          <Link to={collection.path}>{collection.title}</Link>
        </Item>
        <Item>{title}</Item>
      </Breadcrumb>
      <Title>
        {formatMessage({
          id: "composer.title",
          defaultMessage: "Create your carousel",
        })}
      </Title>
      <Subtitle>
        {formatMessage({
          id: "composer.select-base-step-title",
          defaultMessage: "Select your base",
        })}
      </Subtitle>
      <BasesList>
        {bases.map((base) => (
          <ProductCard
            key={base.shopify.storefrontId!}
            product={base}
            behaviour="button"
            onClick={(variantId) =>
              onSelect(base.shopify.storefrontId!, variantId)
            }
          />
        ))}
      </BasesList>
    </div>
  );
}

const Breadcrumb = styled.ol`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  padding: 20px 0;
`;

const Item = styled.li`
  padding: 0;
  margin: 0;
  font-size: 12px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  &:not(:last-child):after {
    content: "/";
    display: inline-block;
    padding: 0 8px;
  }
  a {
    color: #333;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    &::after,
    &::before {
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      bottom: -2px;
      transition: 0.5s all;
      left: 0;
    }
    &::before {
      content: "";
      width: 100%;
      background-color: #333;
    }
    &::after {
      width: 0;
      background-color: ${({ theme }) => theme.colors.main};
    }
    &:hover {
      cursor: pointer;
      font-weight: 600;
      &::after {
        width: 100%;
      }
    }
  }
`;

const Title = styled.h1`
  padding-top: 40px;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
`;

const Subtitle = styled.span`
  display: block;
  margin-top: 10px;
  padding-bottom: 20px;
  font-size: 18px;
  letter-spacing: 0.16em;
  text-align: center;
`;

const BasesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${ProductCard} {
    width: 33%;
    padding: 30px;
    @media (max-width: 960px) {
      width: 50%;
    }
    @media (max-width: 600px) {
      width: 100%;
    }
    @media (max-width: 450px) {
      padding: 20px 0;
    }
  }
`;
