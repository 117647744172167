import React from "react";
import { ILocalizedProduct } from "types";
import { StringParam, useQueryParams } from "use-query-params";
import ConfigureBracelet from "./ConfigureBracelet";
import SelectBracelet from "./SelectBracelet";

interface IProps {
  title: string;
  collection: {
    title: string;
    path: string;
  };
  bases: ILocalizedProduct[];
  instants: ILocalizedProduct[];
}

function Composer({ title, collection, bases, instants }: IProps) {
  const [query, setQuery] = useQueryParams({
    bId: StringParam,
    bVId: StringParam,
  });

  let selectedBase: ILocalizedProduct | undefined;
  if (query.bId && query.bVId) {
    selectedBase = bases.find(
      (base) => base.shopify.storefrontId === query.bId
    )!;
  }

  if (!selectedBase) {
    return (
      <SelectBracelet
        bases={bases}
        title={title}
        collection={collection}
        onSelect={(productId, variantId) =>
          setQuery(
            {
              bId: productId,
              bVId: variantId,
            },
            "push"
          )
        }
      />
    );
  }

  return (
    <ConfigureBracelet
      instants={instants}
      base={selectedBase}
      baseVariantId={query.bVId!}
      onBackToBraceletSelection={() => setQuery({}, "push")}
    />
  );
}

export default Composer;
