import React from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import ShopifyImage from "components/ShopifyImage";

interface IProps {
  id: string;
  index: number;
  imageSrc: string;
  imageAlt?: string | null;
}

function Instant({ id, index, imageSrc, imageAlt }: IProps) {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <Container
          isDropping={snapshot.isDropAnimating}
          isDragging={snapshot.isDragging}
          isOutsideBase={
            (snapshot.isDragging || snapshot.isDropAnimating) &&
            !snapshot.draggingOver
          }
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ShopifyImage src={imageSrc} alt={imageAlt || ""} width={200} />
        </Container>
      )}
    </Draggable>
  );
}

const Container = styled.div<{
  isDragging?: boolean;
  isDropping?: boolean;
  isOutsideBase?: boolean;
}>`
  position: relative;
  border-radius: 4px;
  ${props =>
    props.isDragging &&
    `border: 2px solid ${
      props.theme.colors.main
    }; background-color: rgba(255, 255, 255, 0.3);`}
  opacity: ${({ isDropping, isOutsideBase }) =>
    isOutsideBase ? (isDropping ? 0 : 0.3) : 1};
  height: 80px;
  ${({ isDropping, isOutsideBase }) =>
    isDropping && isOutsideBase ? `transform: scale(0);` : ""}
  ${ShopifyImage} {
    height: 100%;
  }
`;

const InstantImage = styled.img`
  height: 100%;
`;

export default Instant;
