import React, { useMemo, useState } from "react";
import styled from "styled-components";
import produce from "immer";
import { uniqueId, keyBy } from "lodash";
import { AddToCartButton } from "components/AddToCartButton";
import ProductCard from "./ProductCard";
import { LineItemInput, useCart } from "hooks/useCart";
import { useLocale } from "hooks/useLocale";
import { ILocalizedProduct } from "types";
import formatMoney from "utils/common/formatMoney";
import { useTranslation } from "hooks/useTranslation";
import { getAllProductPathLocales } from "utils/common/navigation";
import Bracelet, { IInstant } from "./Bracelet";
import {
  getBaseImage,
  getSelectedInstantsPrice,
  getInstantImage,
  getVariantPrice,
} from "./utils";

interface Props {
  instants: ILocalizedProduct[];
  base: ILocalizedProduct;
  baseVariantId: string;
  onBackToBraceletSelection: () => void;
}

export default function ConfigureBracelet({
  instants,
  base,
  baseVariantId,
  onBackToBraceletSelection,
}: Props) {
  const { formatMessage } = useTranslation();
  const { defaultLocale } = useLocale();
  const { addingToCart, addToCart } = useCart();
  const instantsById = useMemo(
    () => keyBy(instants, "shopify.storefrontId"),
    [instants]
  );

  const [selectedInstants, setSelectedInstants] = useState<IInstant[]>([]);

  const baseImage = getBaseImage(base, baseVariantId);
  if (!baseImage) {
    console.warn(`Missing base image for ${base.dato.title}`);
  }
  const selectedInstantsPrice = getSelectedInstantsPrice(
    selectedInstants,
    instantsById
  );
  const basePrice = getVariantPrice(base, baseVariantId);
  const bundlePrice = basePrice + selectedInstantsPrice;
  const money = formatMoney(bundlePrice * 100, "€{{amount}}");

  const handleAddInstant = (productId: string, variantId: string) => {
    const product = instantsById[productId];
    const instantImage = getInstantImage(instantsById[productId], variantId);
    if (instantImage === undefined) {
      console.warn(`Missing image for instant ${product.shopify.title}`);
      return;
    }
    setSelectedInstants([
      ...selectedInstants,
      {
        id: uniqueId("instant"),
        productId,
        variantId,
        image: {
          src: instantImage.originalSrc as string,
          alt: instantImage.altText,
        },
      },
    ]);
  };

  const handleInstantDroppedOutsideBracelet = (id: string) => {
    const indexToBeRemoved = selectedInstants.findIndex(
      (instant) => instant.id === id
    );
    const newSelectedInstants = produce(selectedInstants, (draft) => {
      draft.splice(indexToBeRemoved, 1);
    });
    setSelectedInstants(newSelectedInstants);
  };

  const handleInstantReorder = (startIndex: number, endIndex: number) => {
    const newSelectedInstants = produce(selectedInstants, (draft) => {
      const [removed] = draft.splice(startIndex, 1);
      draft.splice(endIndex, 0, removed);
    });
    setSelectedInstants(newSelectedInstants);
  };

  const handleAddToCart = async () => {
    const bundleId = `${Date.now()}`;
    const input = createAddToCartInput(bundleId);
    addToCart(input);
    gaTrackAddToCart();
  };

  const createAddToCartInput = (bundleId: string) => {
    let input: LineItemInput[] = [];
    const baseVariant = base.shopify.variants.find(
      (variant) => variant.storefrontId === baseVariantId
    )!;
    input.push({
      variant: {
        id: baseVariant.storefrontId!,
        price: baseVariant.price,
        compareAtPrice: baseVariant.compareAtPrice,
        image: baseVariant.image
          ? {
              originalSrc: baseVariant.image.originalSrc!,
              altText: baseVariant.title || "",
            }
          : undefined,
        selectedOptions: baseVariant.selectedOptions as any,
      },
      quantity: 1,
      allLocaleValues: {
        titles: base.dato._allTitleLocales as any,
        paths: getAllProductPathLocales(base.dato, defaultLocale),
      },
      customAttributes: [{ key: "_bundleId", value: bundleId }],
    });
    input = input.concat(
      selectedInstants.map<LineItemInput>((selectedInstant, index) => {
        const product = instantsById[selectedInstant.productId];
        const productVariant = product.shopify.variants.find(
          (variant) => variant.storefrontId === selectedInstant.variantId
        )!;
        const paths = getAllProductPathLocales(product.dato, defaultLocale);
        return {
          variant: {
            id: productVariant.storefrontId!,
            price: productVariant.price,
            compareAtPrice: productVariant.compareAtPrice,
            image: productVariant.image
              ? {
                  originalSrc: productVariant.image.originalSrc!,
                  altText: productVariant.title || "",
                }
              : undefined,
            selectedOptions: productVariant.selectedOptions as any,
          },
          quantity: 1,
          allLocaleValues: {
            titles: product.dato._allTitleLocales as any,
            paths,
          },
          customAttributes: [
            { key: "_bundleId", value: bundleId },
            { key: "_posizione", value: `${index + 1}` },
          ],
        };
      })
    );
    return input;
  };

  const gaTrackAddToCart = () => {
    let productList = [];
    const baseVariant = base.shopify.variants.find(
      (variant) => variant.storefrontId === baseVariantId
    )!;
    const shopifyAdminProductId = base.shopify.shopifyId;

    const shopifyAdminVariantId = baseVariant.shopifyId;

    productList.push({
      id: shopifyAdminProductId,
      sku: baseVariant.sku,
      name: base.shopify.title,
      category: base.shopify.productType,
      variant: shopifyAdminVariantId,
      variantName:
        base.shopify.variants.length > 1 ? baseVariant.title : undefined,
      price: baseVariant.price!,
      quantity: 1,
    });
    productList = productList.concat(
      selectedInstants.map((instant) => {
        const product = instantsById[instant.productId];
        const productVariant = product.shopify.variants.find(
          (variant) => variant.storefrontId === instant.variantId
        )!;
        const shopifyAdminProductId = product.shopify.shopifyId;

        const shopifyAdminVariantId = productVariant.shopifyId;

        return {
          id: shopifyAdminProductId,
          sku: productVariant.sku,
          name:
            product.shopify.variants!.length > 1
              ? `${product.shopify.title} - ${productVariant.title}`
              : product.shopify.title,
          category: product.shopify.productType,
          variant: shopifyAdminVariantId,
          variantName:
            product.shopify.variants.length > 1
              ? productVariant.title
              : undefined,
          price: productVariant.price!,
          quantity: 1,
        };
      })
    );

    const dataLayerProducts: {
      id: string;
      name: string;
      variant: string;
      price: string;
      quantity: number;
      product_id: string;
      variant_id: string;
    }[] = [];
    productList.forEach((product) => {
      const productId = product.id?.replace("gid://shopify/Product/", "");
      const variantId = product.variant?.replace(
        "gid://shopify/ProductVariant/",
        ""
      );
      dataLayerProducts.push({
        id: product.sku!,
        name: product.name!,
        variant: product.variantName!,
        price: product.price,
        quantity: product.quantity,
        product_id: productId!,
        variant_id: variantId!,
      });
    });
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: "addToCart",
      eventCategory: "Ecommerce",
      eventAction: "Add to Cart",
      ecommerce: {
        currencyCode: "EUR",
        add: { products: dataLayerProducts },
      },
    });
  };

  return (
    <Container>
      <Header>
        <Back onClick={onBackToBraceletSelection}>
          {formatMessage({
            id: "composer.change-your-bracelet-base",
            defaultMessage: "Change your bracelet base",
          })}
        </Back>
        <Title>
          {formatMessage({
            id: "composer.title",
            defaultMessage: "Create your carousel",
          })}
        </Title>
        <Subtitle>
          {formatMessage({
            id: "composer.customise-step-title",
            defaultMessage: "Customise your carousel",
          })}
        </Subtitle>
        <Bracelet
          base={{
            productId: base.shopify.shopifyId!,
            variantId: baseVariantId,
            image: {
              src: baseImage!.originalSrc!,
              alt: baseImage!.altText,
            },
          }}
          instants={selectedInstants}
          onInstantDroppedOutside={handleInstantDroppedOutsideBracelet}
          onReorderInstant={handleInstantReorder}
        />

        <FilterBar>
          <FilterWrapper>
            <Spacer />
            <BundleAddToCartButton
              isLoading={addingToCart}
              onClick={handleAddToCart}
            >
              {bundlePrice > 0 ? `${money} - ` : ""}
              {formatMessage({
                id: "button.addToCart",
                defaultMessage: "Add to cart",
              })}
            </BundleAddToCartButton>
          </FilterWrapper>
        </FilterBar>
      </Header>
      <InstantsList>
        {instants
          .filter((instant) =>
            instant.shopify.variants.find((variant) => variant.availableForSale)
          )
          .map((instant) => (
            <InstantProductCard
              key={instant.shopify.storefrontId as string}
              product={instant}
              onClick={(variantId) =>
                handleAddInstant(
                  instant.shopify.storefrontId as string,
                  variantId
                )
              }
            />
          ))}
      </InstantsList>
    </Container>
  );
}

const Container = styled.div``;

const Header = styled.div`
  position: fixed;
  width: 1200px;
  background: #fff;
  z-index: 1;
  @media (max-width: 1240px) {
    width: 100%;
    margin-left: -20px;
  }
`;

const Back = styled.button`
  color: #333;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  border: none;
  font-family: soleil, sans-serif;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: 12px;
  letter-spacing: 0.08em;
  position: absolute;
  left: 20px;
  top: 26px;
  z-index: 2;
  @media (max-width: 860px) {
    bottom: 20px;
    top: auto;
  }
  &::after,
  &::before {
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    bottom: -2px;
    transition: 0.5s all;
    left: 0;
  }
  &::before {
    content: "";
    width: 100%;
    background-color: #333;
  }
  &::after {
    width: 0;
    background-color: ${({ theme }) => theme.colors.main};
  }
  &:hover {
    cursor: pointer;
    font-weight: 600;
    &::after {
      width: 100%;
    }
  }
`;

const Title = styled.h1`
  padding-top: 20px;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  height: 50px;
  box-sizing: border-box;
  @media (max-width: 860px) {
    font-size: 16px;
  }
`;

const Subtitle = styled.span`
  display: block;
  margin-top: 10px;
  padding-bottom: 20px;
  font-size: 18px;
  letter-spacing: 0.16em;
  text-align: center;
`;

const InstantProductCard = styled(ProductCard)``;

const InstantsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 355px;
  justify-content: center;
  width: calc(100% + 40px);
  margin-left: -20px;
  @media (max-width: 450px) {
    padding-top: 300px;
  }
  ${InstantProductCard} {
    flex: 0 0 120px;
    padding: 20px;
    @media (max-width: 960px) {
      flex: 0 0 100px;
      padding: 15px;
    }
    @media (max-width: 600px) {
      flex: 0 0 90px;
      padding: 10px;
    }
    @media (max-width: 600px) {
      flex: 0 0 80px;
      padding: 10px;
    }
  }
`;

const FilterBar = styled.div`
  background-color: ${(props) => props.theme.colors.grey};

  width: 100vw;
  left: 0;
  position: fixed;
`;

const BundleAddToCartButton = styled(AddToCartButton)`
  padding: 0 20px;
  max-width: none;
  width: 340px;
  @media (max-width: 450px) {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: calc(100% - 40px);
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1240px) {
    width: 100%;
  }
`;

const Spacer = styled.div`
  flex-grow: 1;
`;
