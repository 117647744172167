import { IInstant } from "./Bracelet";
import { ILocalizedProduct } from "types";
import { mapMediaToImages } from "utils/image";

export function getSelectedInstantsPrice(
  selectedInstants: IInstant[],
  instantsById: { [id: string]: ILocalizedProduct }
): number {
  return selectedInstants.reduce((sum, instant) => {
    const product = instantsById[instant.productId];
    return sum + getVariantPrice(product, instant.variantId);
  }, 0);
}

export function getVariantPrice(
  product: ILocalizedProduct,
  variantId: string
): number {
  const variant = product.shopify.variants.find(
    (variant) => variant.storefrontId === variantId
  );
  return parseFloat(variant!.price!);
}

export function getVariantCompareAtPrice(
  product: ILocalizedProduct,
  variantId: string
): number {
  const variant = product.shopify.variants.find(
    (variant) => variant.storefrontId === variantId
  );
  return parseFloat(variant!.compareAtPrice!);
}

export function getBaseImage(base: ILocalizedProduct, variantId: string) {
  const variant = getShopifyVariant(base, variantId)!;

  const image = mapMediaToImages(base.shopify.media)?.find(
    (image) =>
      typeof image!.altText === "string" &&
      image!.altText === `Carousel ${variant.title}`
  );
  return image ? image : undefined;
}

export function getInstantImage(product: ILocalizedProduct, variantId: string) {
  const variant = getShopifyVariant(product, variantId)!;
  const image = mapMediaToImages(product.shopify.media).find(
    (image) =>
      typeof image!.altText === "string" &&
      image!.altText === `Carousel ${variant.title}`
  );
  return image ? image : undefined;
}

export function getShopifyVariant(
  product: ILocalizedProduct,
  variantId: string
) {
  return product.shopify.variants.find(
    (variant) => variant.storefrontId === variantId
  );
}
