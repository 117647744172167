import React from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import Base from "./Base";
import Instant from "./Instant";

interface IProps {
  base: IBase;
  instants: IInstant[];
  onInstantDroppedOutside?: (id: string) => void;
  onReorderInstant?: (startIndex: number, endIndex: number) => void;
}

export interface IBase {
  productId: string;
  variantId: string;
  image: {
    src: string;
    alt?: string | null;
  };
}

export interface IInstant {
  id: string;
  productId: string;
  variantId: string;
  image: {
    src: string;
    alt?: string | null;
  };
}

function Bracelet({
  base,
  instants,
  onInstantDroppedOutside,
  onReorderInstant
}: IProps) {
  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      if (onInstantDroppedOutside) {
        onInstantDroppedOutside(result.draggableId);
      }
    } else if (result.destination.index !== result.source.index) {
      if (onReorderInstant) {
        onReorderInstant(result.source.index, result.destination.index);
      }
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container>
        <Droppable droppableId="bracelet" direction="horizontal">
          {provided => (
            <InstantListContainer
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <InstantList>
                <Base src={base.image.src} />
                {instants.map((instant, index) => {
                  return (
                    <Instant
                      key={instant.id}
                      id={instant.id}
                      index={index}
                      imageSrc={instant.image.src}
                      imageAlt={instant.image.alt}
                    />
                  );
                })}
                {provided.placeholder}
              </InstantList>
            </InstantListContainer>
          )}
        </Droppable>
      </Container>
    </DragDropContext>
  );
}

const Container = styled.div`
  position: relative;
  margin-top: 20px;
  ::before {
    left: 0;
  }
  ::after {
    right: 0;
    transform: rotate(180deg);
  }
  ::before,
  ::after {
    content: "";
    position: absolute;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(
      87deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.9) 30%,
      rgba(255, 255, 255, 0) 70%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      87deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.9) 30%,
      rgba(255, 255, 255, 0) 70%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      87deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.9) 30%,
      rgba(255, 255, 255, 0) 70%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    top: 0;

    height: 120px;
    width: 80px;
    z-index: 1;
  }
`;

const InstantListContainer = styled.div`
  overflow-x: auto;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 20px;
  height: 160px;
  display: flex;
  transition: 0.3s all;
  @media (max-width: 860px) {
    padding-bottom: 60px;
    height: 160px;
  }
  ::-webkit-scrollbar {
    height: 5px;
    background-color: rgba(151, 123, 43, 0);
  }
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    border: 0px solid rgba(255, 255, 255, 0);
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(151, 123, 43, 0.2);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(151, 123, 43, 0.5);
    &:hover {
      background-color: rgba(151, 123, 43, 0.8);
    }
  }
`;

const InstantList = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  padding-left: 120px;
  padding-right: 120px;
`;

export default Bracelet;
