import React from "react";
import { graphql } from "gatsby";
import { partition } from "lodash";
import Wrapper from "components/Wrapper";
import { DatoCmsProduct, ShopifyProduct } from "graphql-typings";
import { ILocalizedProduct } from "types";
import Composer from "./Composer";
import { useTranslation } from "hooks/useTranslation";
import SEO from "components/SEO";
import { universalBtoa } from "utils/product";

interface Props {
  data: {
    allDatoCmsProduct: { nodes: DatoCmsProduct[] };
    allShopifyProduct: { nodes: ShopifyProduct[] };
  };
  pageContext: {
    locale: string;
    defaultLocale: string;
    allPathLocales: string[];
    pagePath: string;
    title: string;
    collection: {
      title: string;
      path: string;
    };
    productIds: string[];
  };
}

const ComposableTemplate = ({ data, pageContext }: Props) => {
  const { pagePath, title, collection, productIds } = pageContext;

  const {
    allDatoCmsProduct: { nodes: datocmsProducts },
    allShopifyProduct: { nodes: shopifyProducts },
  } = data;

  const { formatMessage } = useTranslation();

  const products = productIds
    .map((productId) => {
      const datocmsProduct = datocmsProducts.find(
        (product) => product.shopifyId === universalBtoa(productId)
      );
      const shopifyProduct = shopifyProducts.find(
        (product) => product.storefrontId === productId
      );
      return { dato: datocmsProduct, shopify: shopifyProduct };
    })
    .filter((product) => !product.shopify?.tags.includes("non-componibile"));

  const [bracelets, instants] = partition(products, {
    dato: { category: { shopifyHandle: "bracciali" } },
  });

  return (
    <Wrapper>
      <SEO
        title={formatMessage({ id: "SEO.carouselTitle" })}
        description={formatMessage({ id: "SEO.carouselDescription" })}
        link={[
          { rel: "canonical", href: "https://ruedesmille.com" + pagePath },
        ]}
      />
      {typeof window !== `undefined` ? (
        <Composer
          title={title}
          collection={collection}
          bases={bracelets as ILocalizedProduct[]}
          instants={instants as ILocalizedProduct[]}
        />
      ) : (
        <div>Loading..</div>
      )}
    </Wrapper>
  );
};

export const query = graphql`
  query composeQuery(
    $locale: String
    $productIds: [String]
    $storefrontProductIds: [String]
  ) {
    allDatoCmsProduct(
      filter: {
        shopifyId: { in: $storefrontProductIds }
        locale: { eq: $locale }
      }
    ) {
      nodes {
        __typename
        shopifyId
        title
        handle
        _allHandleLocales {
          locale
          value
        }
        _allTitleLocales {
          locale
          value
        }
        category {
          shopifyHandle
          handle
          _allHandleLocales {
            locale
            value
          }
        }
      }
    }
    allShopifyProduct(
      sort: { fields: [handle], order: ASC }
      filter: { storefrontId: { in: $productIds } }
    ) {
      nodes {
        shopifyId
        storefrontId
        handle
        description
        productType
        title
        media {
          id
          shopifyId
          mediaContentType
          ... on ShopifyMediaImage {
            image {
              originalSrc
              altText
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        variants {
          availableForSale
          shopifyId
          storefrontId
          title
          compareAtPrice
          price
          sku
          media {
            id
            shopifyId
            mediaContentType
            ... on ShopifyMediaImage {
              image {
                originalSrc
                altText
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          selectedOptions {
            name
            value
          }
        }
        tags
      }
    }
  }
`;

export default ComposableTemplate;
