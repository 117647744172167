import React from "react";
import styled from "styled-components";

interface IProps {
  src: string;
}

function Base({ src }: IProps) {
  return (
    <Container>
      <Left src={src} />
      <Middle src={src} />
      <Right src={src} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-46%);
  left: 0;
  min-width: 100%;
  padding: 0 40px;
`;

const Left = styled.div<{ src: string }>`
  background-size: 100%;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  width: 70px;
  height: 70px;
`;

const Right = styled.div<{ src: string }>`
  background-size: 100%;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: 0 -140px;
  width: 70px;
  height: 70px;
`;

const Middle = styled.div<{ src: string }>`
  background-size: auto 210px;
  background-image: url(${props => props.src});
  flex-grow: 1;
  background-repeat: repeat-x;
  background-position: 0 -70px;
  width: 70px;
  height: 70px;
`;

export default Base;
